import React from 'react'
import ReactDOM from 'react-dom'

const value = 
  {
    "basics": 
    {
      "name": "Jonathan Tran",
      "email": "jonpn.tran@gmail.com",
      "url": "https://jonathantran.cloud",
      "summary": "",
      "location": 
      {
        "city": "Mesa",
        "countryCode": "US",
        "state": "Arizona"
      }
    },
    "employmentHistory": 
    [
      {
      "name": "Mayo Clinic",
      "position": "Systems Engineer",
      "url": "https://mayoclinic.org",
      "startDate": "2018-04-28",
      "endDate": "Present",
      "highlights": 
        [
          "Participate in incident, task-queue, and inventory management.",
          "Implement automation methods to improve current processes and reporting.",
          "Create/improve process documentation and construct reports and visualization for upper management strategy.",
          "Monitor, control and audit physical data center security.",
          "Organize and maintain IP address VLAN assignments."
        ]
      },
      {
        "name": "TTEC",
        "position": "Data Center Engineer",
        "url": "https://ttec.com",
        "startDate": "2016-03",
        "endDate": "2018-04",
        "highlights": 
        [
          "Designed MDF infrastructure builds for new call centers across the country.",
          "Travelled to new call centers for network backbone turn-up and installation.",
          "Resolved trouble tickets and participated in change management."
        ]
      },
      {
        "name": "Level 3 Communications/Lumen",
        "position": "Field Technician",
        "url": "https://lumen.com",
        "startDate": "2014-02",
        "endDate": "2016-03",
        "highlights": 
        [
          "Racked and stacked devices for main Data Center, 3rd-party Data Centers and customer locations across the Phoenix Metro Area.",
          "Turned up new network infrastructure for Starbucks/Level3 WIFI partnership.",
          "Operated test sets for circuit continuity troubleshooting.",
          "Performed critical infrastructure PMIs.",
          "Located and marked fiber lines on long-haul routes for outage prevention."
        ]
      }
    ],
    "education": 
    [
      {
      "institution": "University of California Santa Barbara",
      "url": "https://ucsb.edu/",
      "area": "Linguistics",
      "studyType": "Bachelor",
      "startDate": "2009",
      "endDate": "2013"
      }
    ],
    "certificates": 
    [
      {
        "name": "AWS Certified Cloud Practitioner",
        "date": "2022-05-16",
        "issuer": "AWS",
        "url": "https://www.credly.com/badges/f9c338f6-b635-44f9-92c2-934510df79a4"
      },
      {
        "name": "CCNA",
        "date": "2018-06-04",
        "issuer": "Cisco",
        "verificationNumber": "432564173767FSCL"
      }
    ],
    "skills": 
    [
      {
      "name": "Cloud Technologies",
      "keywords": 
        [
          "AWS",
          "GCP",
          "Linode"
        ]
      }
    ],
    "interests": 
    [
      {
        "name": "Rugby",
        "team": "Red Mountain Warthogs",
        "date": "2019-Present"
      }
    ],
    "references": 
    [
      {
        "name": "James Kirk",
        "company": "TTEC",
        "title": "Principal Network Engineer",
        "contact": "303-642-6134"
      },
      {
        "name": "Jeremy Delp",
        "company": "Zoom",
        "title": "Data Center Operations Manager",
        "contact": "720-480-8650"
      }
    ],
    "projects": 
    [
      {
        "name": "Cloud Resume",
        "description": "Deployed and maintained a JSON-formatted resume on AWS at jonathantran.cloud, using the schema from https://jsonresume.org/schema/",
        "keywords": 
        [
          "AWS", "S3", "Route 53", "CloudFront", "Certificate Manager", "ReactJS", "HTML", "CSS", "Javascript"
        ]
      },
      {
        "name": "DCIM Tool",
        "description": "Deployed and developed a data center inventory management tool for the Mayo Clinic",
        "keywords": 
          [
            "Python", "MariaDB", "Docker", "Linux", "ReactJS", "Javascript", "ServiceNOW", "Postman"
          ]
      },
      {
        "name": "Ping Monitoring Audit Tool",
        "description": "Developed and internally distributed a python command line tool, which audited an inventory of devices and compared their information with the ServiceNOW catalog. ",
        "keywords": 
          [
            "Python", "Docker", "ServiceNOW", "Postman"
          ]
      }
    ]
  }

const App = () => (
  <pre>
    <code>
      {JSON.stringify(value, null, 2)}
    </code>
  </pre>
)


ReactDOM.render(<App />, document.getElementById('app'))
